










































































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import KeFu from "../layout/KeFu.vue";

@Component({
  components: { LayoutHeader, LayoutFooter,KeFu },
})
export default class Order extends Vue {
  @Provide() siteConfig:any = [];
  @Provide() order_info: any = [];
  @Provide() pay_type: Number = 1;
  @Provide() is_login:Number = 0;
  created() {
    let order_sn = String(this.$route.query.sn);
    // goods_id = this.$route.params.id;
    this.getSystemConfig();
    this.loadData(order_sn);
  }
  // 获取配置信息
  getSystemConfig() {
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.siteConfig = res.data.data.config;
          document.title = this.siteConfig.webtitle;
          if(this.siteConfig.channel_alipay == 1) {
            this.pay_type = 1;
            return false;
          }
          if(this.siteConfig.channel_wxpay == 1) {
            this.pay_type = 2;
            return false;
          }
          if(this.siteConfig.channel_auth_wxpay == 1) {
            this.pay_type = 3;
            return false;
          }
          if(this.is_login == 1 && this.order_info.member_money > 0) {
            this.pay_type = 4;
            return false;
          }
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  toIndex() {
    this.$router.push("/");
  }
  //切换支付方式
  togglePay(index: Number) {
    if (this.pay_type == index) return;
    this.pay_type = index;
  }
  //返回上一页面
  toBack() {
      this.$router.go(-1);
  }
  //确认购买
  confirmPay() {
    let order_sn = this.order_info.order_sn;
    let pay_type = 'alipay';
    if(this.pay_type == 2) {
      pay_type = 'wxpay';
    } else if(this.pay_type == 3) {
      pay_type = 'wxauthpay';
    } else if(this.pay_type == 9) {
      pay_type = 'yuepay';
      this.yuePay(order_sn);
      return;
    }
    this.$router.push({ name: 'pay', query: { sn: order_sn,pay_type: pay_type }});
  }
  // 余额支付
  yuePay(order_sn:String) {
    let forms = {
        m: "pay.pay",
        order_sn: order_sn,
        pay_type: 'yuepay',
        check_stock_flg: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let order_info = res.data.data;
              if(order_info.pay_status == 1) {
                this.$message({
                  showClose: true,
                  message: '支付成功',
                  type: 'success'
                });
                setTimeout(() => {
                  this.$router.push({ name: 'orderReturn', query: { sn: order_info.order_sn }});
                }, 2000);
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
                });
              }
          } else {
              this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
              });
          }
      },
      (err: any) => {
          console.log(err);
      }
    );
  }
  // 加载数据
  loadData(order_sn:String) {
    let utils = (this as any).$utils;
    let forms = {
        m: "order.getOrderInfo",
        order_sn: order_sn
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let order_info = res.data.data.order_info;
              this.order_info = order_info;
              if(order_info.code_pay_flg == 0) {
                this.$confirm('订单支付超时，请重新下单', '温馨提示', {
                    confirmButtonText: '确定',
                    type: 'info',
                    showCancelButton: false,
                    showClose: false,
                    center: true
                    }).then(() => {
                      this.$router.go(-1);
                    }).catch(() => {})
              }
          } else {
              this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
              });
          }
      },
      (err: any) => {
          console.log(err);
      }
    );
    let tsToken = utils.praseStrEmpty(localStorage.getItem('tsToken'));
    if(tsToken != '') {
      this.is_login = 1;
    }
  }
}
